import React ,{useState }from 'react'
import { Route, useHistory, Link  } from 'react-router-dom';
// import Front from './front/App.js'
// import Back from './dashboard/App.js'
import Navbar from './Components/Navbar/Navbar'
import Home from './Pages/front/home'
import Login from './Pages/Users/Login'
import Register from './Pages/Users/Register'
import Unauthorized from './Pages/Unauthorized'
import Terms from './Pages/front/terms'
//dashboard
import Dashboard from './Pages/dashboard/App'
import Create from './Pages/dashboard/Create'
import Read from './Pages/dashboard/Read'
import Single from './Pages/dashboard/Single'

import ProtectedRoute from './Components/ProtectedRoute';
import './App.scss'

function App() {
  const [user, setUser] = useState(false);
  const history = useHistory();
  const handleLogin = data => {
    // console.log(data.password)
    if(data){
      setUser(true);
      history.push('/dashboard');
      
    }
    
  }
  const handleLogout = e => {
    e.preventDefault();
    setUser(false);
  }
  return (
    <React.Fragment>
      <Navbar/>
      <main>
        <Route path="/" exact component={Home} />
        <Route exact  path="/login" handleLogin={handleLogin} render={props => <Login {...props} user={user.toString()} handleLogin={handleLogin} />} />
        
        <ProtectedRoute exact  path="/dashboard" user={user}  component={Dashboard} />
        <ProtectedRoute path="/dashboard/create" user={user}  component={Create} />
        <ProtectedRoute path="/dashboard/read" user={user}  component={Read} />
        <ProtectedRoute path="/dashboard/single/:id" user={user}  component={Single} />
        <Route exact path='/unauthorized' component={Unauthorized} />
        <Route exact path='/register' component={Register} />
        <Route exact path='/terms' component={Terms} />
      </main>
      <footer class="footer mt-auto py-3">
        <div class="container">
          <Link className="footerLink" to="/terms">使用者注意事項</Link>
          <span class="text-muted">© 2020 - Moondream Reality</span>


        </div>
      </footer>
      {/* The corresponding component will show here if the current URL matches the path */}
      {/* <Route path="/" exact component={Front} /> */}
      {/* <Route path="/back" component={Back} /> */}
    </React.Fragment>
  );
}

export default App;
