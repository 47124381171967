import React from 'react'
import { Link } from 'react-router-dom';
import LogoImg from '../../img/logotext.png'
function Navbar() {
  return (
  <header className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-dark container">
    <Link to="/" className="h5 my-0 me-md-auto  text-light logoWord" > <img src={LogoImg} alt=""/> </Link>
    <div className="loginNav">
      <Link rel="stylesheet" to="/dashboard/create" >新增上傳</Link>
      <Link rel="stylesheet" to="/dashboard/read" >你已上傳的內容</Link>
      <Link to="/login">登入</Link>
    </div>

  </header>
  )
}

export default Navbar
