import React,{useState} from 'react'
import { useForm } from "react-hook-form";
import {apiUrl} from '../../hooks/Url' 
function Create() {
  const { register, handleSubmit} = useForm();
  const [value, setValue] = useState("")
  const [haveFile1 , setHaveFile1] = useState(false)
  const [selectedWallFile, setSelectedWallFile] = useState(null);
  const [selectedGroundFile, setSelectedGroundFile] = useState(null);
  const [selectedUnityFile, setSelectedUnityFile] = useState(null);
  const [nowId , setNowId] = useState("")
  let token = localStorage.getItem('Authorization');
  const onChange = (e) =>{
    // const file = e.target.files[0]
    console.log(e.target.files[0])
    setHaveFile1(!haveFile1)
  }
  const onSubmit = data => {
    console.log(data)
    const wallFormData = new FormData();
    const groundFormData = new FormData();
    const unityFormData = new FormData();
    if(selectedWallFile) { 
      wallFormData.append("file", selectedWallFile);
    }
    if(selectedGroundFile) { 
      groundFormData.append("file", selectedGroundFile);
    }
    if(selectedUnityFile){
      unityFormData.append("file", selectedUnityFile);
    }
    
    // const storageRef = app.storage().ref()
    // const fileRef = storageRef.child(data.)
    // props.handleLogin(data)
    fetch(apiUrl+'assets',{
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
        "Authorization": token
      },
      body:JSON.stringify(data)
    }).then((res)=>{
      console.log(res)
      if(res.status === 201){
        console.log('success')
        setValue('建立專案成功')
        return res.json()
      }else if (res.status === 401){
        setValue('建立專案失敗，請重新檢查')
        return
      }      
    })
    .then(jsonResponse=> {
        setNowId(jsonResponse.id)
        console.log('nowId' + '上傳檔案')
        postUpdateWallFile(jsonResponse.id , wallFormData)
        postUpdateGroundFile(jsonResponse.id , groundFormData)
        postUpdateUnityFile(jsonResponse.id , unityFormData)
    })
    .catch((err)=>{console.log(err)})
  }

  const postUpdateWallFile = (nowId , file) =>{
    
    fetch(apiUrl+'assets/'+ nowId + '/wall_video' ,{
      method: 'PUT',
      headers: {
        // 'Content-Type': 'multipart/form-data',
        "Authorization": token
      },
      body:file
    }).then((res)=>{
      if(res.status === 201){
        console.log('success')
        setValue('上傳牆壁檔案成功')
        
      }else if (res.status ===400){
        setValue('上傳牆壁檔案失敗，請重新檢查')
      }
    })
    .catch((err)=>{console.log(err)})
  };
  const postUpdateGroundFile = (nowId , file) =>{
  //傳地板影片
    fetch(apiUrl+'assets/'+ nowId + '/ground_video' ,{
      method: 'PUT',
      headers: {
        // 'Content-Type': 'multipart/form-data',
        "Authorization": token
      },
      body:file
    }).then((res)=>{
      if(res.status === 201){
        console.log('success')
        setValue('上傳地板檔案成功')
        
      }else if (res.status ===400){
        setValue('上傳地板檔案失敗，請重新檢查')
      }
    })
    .catch((err)=>{console.log(err)})
  };

  const postUpdateUnityFile = (nowId , file) =>{
    //傳地板影片
      fetch(apiUrl+'assets/'+ nowId + '/unity_project' ,{
        method: 'PUT',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          "Authorization": token
        },
        body:file
      }).then((res)=>{
        if(res.status === 201){
          console.log('success')
          setValue('上傳unity檔案成功')
          
        }else if (res.status ===400){
          setValue('上傳unity檔案失敗，請重新檢查')
        }
      })
      .catch((err)=>{console.log(err)})
    };


  return (
    // "english_title":"new asset","chinese_title":"測試名稱","description":"測試介紹文字"
    <div className="container">
      {value.length > 0 && <div class="alert alert-primary" role="alert">{value}</div>}
      <h3 className="text-light">新增專案</h3>
      <form onSubmit={handleSubmit(onSubmit)} className="text-light">
         <div className="mb-3">
          <label htmlFor="InputChineseTitle" className="form-label">標題</label>
          <input type="text" className="form-control" id="InputChineseTitle"  ref={register} name="chinese_title"/>
        </div>
        <div className="mb-3">
          <label htmlFor="InputEnglishTitle" className="form-label" >英文標題</label>
          <input type="text" className="form-control" id="InputEnglishTitle"  ref={register} name="english_title" defaultValue="new asset"/>
        </div>
        <div className="mb-3">
          <label htmlFor="InputDescTitle" className="form-label">簡介</label>
          <input type="text" className="form-control" id="InputDescTitle"  ref={register} name="description" defaultValue="test"/>
        </div>
        <div className="input-group mb-3">
          <label className="input-group-text" htmlFor="inputGroupFile01">上傳牆面影片(1920px*935px)</label>
          <input type="file" className="form-control" id="inputGroupFile01" ref={register} name="wall_video" onChange={(e) => setSelectedWallFile(e.target.files[0])} />
        </div>
        <div className="input-group mb-3">
          <label className="input-group-text" htmlFor="inputGroupFile01">上傳地板影片(1920px*1920px)</label>
          <input type="file" className="form-control" id="inputGroupFile01" ref={register} name="ground_video" onChange={(e) => setSelectedGroundFile(e.target.files[0])}  />
        </div>
        <div className="input-group mb-3">
          <label className="input-group-text" htmlFor="inputGroupFile03">上傳執行檔案(Unity壓縮檔)</label>
          <input type="file" className="form-control" id="inputGroupFile03" ref={register} name="unity_file" onChange={(e) => setSelectedUnityFile(e.target.files[0])}  />
        </div>
        <input type="submit" className="btn btn-primary" value="送出上傳"/>
      </form>
      <div class="card mt-3">
        <div class="card-header">
          檔案上傳規範
        </div>
        <div class="card-body">
          <blockquote class="blockquote mb-0">
            <p>下列規範說明在 Expo Moondream Reality 上傳專案與影片時需要遵守的格式規格。</p>
            <ul>
              <li>檔案格式 
                  <div>preview影片：mp4、mov、avi 不侷，檔案大小500MB以下</div> 
                  <div>專案：exe檔，檔案大小500MB以下</div>
              </li>
              <li>畫面播放速率
                  <div>30fps</div>
              </li>
              <li>影片解析度
                  <div>牆面影片  1920*935</div>
                  <div>地面影片   1920*1920</div>

              </li>
            </ul>
          </blockquote>
        </div>
      </div>

      
    </div>
  )
}

export default Create
