import React from 'react';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <div className='container text-light'>
      <div className="row mt-3">
        <div className="col align-self-center mx-auto">
          <div className="message">
            
            <h1>此頁面需登入才看得到</h1>
            <p></p>
          </div>
          <p><Link to='/'>登入</Link></p>
        </div>
      </div>
      
    </div>
  )
}

export default Unauthorized;