/* Babylon JS is available as **npm** package.  
You can easily build a simple `React` Component around a `canvas` and Babylon JS
I have created a minimal example with React+ Babylon:
 */
import React, { Component } from "react";
import * as BABYLON from "babylonjs";
// import {apiUrl} from '../../hooks/Url' 
var scene;
var boxMesh;
/**
 * Example temnplate of using Babylon JS with React
 */
class BabylonScene extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      useWireFrame: false, 
      shouldAnimate: false,
    };
    this.wallPath = 'https://expo.moondreamreality.com'+this.props.wallPath
    this.groundPath = 'https://expo.moondreamreality.com'+this.props.groundPath
  }

  componentDidMount() {
    // console.log(this.wallPath)
    // console.log(this.groundPath)
    //apiUrl+'assets/'+ this.props.assetsid + '/wall_video'
    // let token = localStorage.getItem('Authorization');
    // fetch(apiUrl+'assets/'+ this.props.assetsid,{
    //   headers: {
    //     'Content-Type':'application/json',
    //     "Authorization": token
    //   },
    // })
    // .then(response => {
    //   return response.json()
    // }).then(data => {
    //   this.state.wallvideoPath = data.wall_video[0].file
    //   this.state.groundvideoPath= data.ground_video[0].file
    // })


    // start ENGINE
    this.engine = new BABYLON.Engine(this.canvas, true);

    //Create Scene
    scene = new BABYLON.Scene(this.engine);

    //--Light---
    this.addLight();

    //--Camera---
    this.addCamera();

    //--Meshes---
    this.addModels(this.wallPath ,this.groundPath);

    //--Ground---
    // this.addGround();

    // Add Events
    // window.addEventListener("resize", this.onWindowResize, false);

    // Render Loop
    this.engine.runRenderLoop(() => {
      scene.render();
    });

  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize, false);
  }

  onWindowResize () {
    this.engine.resize();
  };

  /**
   * Add Lights
   */
  addLight  ()  {
    //---------- LIGHT---------------------
    // Create a basic light, aiming 0,1,0 - meaning, to the sky.
    var light = new BABYLON.HemisphericLight(
      "light1",
      new BABYLON.Vector3(0, 10, 0),
      scene
    );
  };

  /**
   * Add Camera
   */
  addCamera  ()  {
    // ---------------ArcRotateCamera or Orbit Control----------
    var camera = new BABYLON.ArcRotateCamera(
      "Camera",
      Math.PI / 2,
      Math.PI / 4,
      4,
      BABYLON.Vector3.Zero(),
      scene
    );
    camera.inertia = 0;
    camera.angularSensibilityX = 250;
    camera.angularSensibilityY = 250;

    // This attaches the camera to the canvas
    camera.attachControl(this.canvas, true);
    camera.setPosition(new BABYLON.Vector3(5, 5, 5));
  };

  /**
   * Create Stage and Skybox
   */
  addGround ()  {
    // Create a built-in "ground" shape.
    var ground = BABYLON.MeshBuilder.CreateGround(
      "ground1",
      { height: 6, width: 6, subdivisions: 2 },
      scene
    );
    var groundMaterial = new BABYLON.StandardMaterial("grass0", scene);
    groundMaterial.diffuseTexture = new BABYLON.Texture(
      "./assets/ground.jpeg",
      scene
    );
    ground.material = groundMaterial;

    //Add SkyBox
    var photoSphere = BABYLON.Mesh.CreateSphere("skyBox", 16.0, 50.0, scene);
    var skyboxMaterial = new BABYLON.StandardMaterial("smat", scene);
    skyboxMaterial.emissiveTexture = new BABYLON.Texture(
      "assets/skybox.jpeg",
      scene,
      1,
      0
    );
    skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
    skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
    skyboxMaterial.emissiveTexture.uOffset = -Math.PI / 2; // left-right
    skyboxMaterial.emissiveTexture.uOffset = 0.1; // up-down
    skyboxMaterial.backFaceCulling = false;
    photoSphere.material = skyboxMaterial;
  };

  /**
   * Add Models
   */
  addModels(wallPath,groundPath) {
    // Add BOX
    // console.log( wallPath)
    // console.log(groundPath)
    BABYLON.SceneLoader.Append('/expo_box/', "expo_box.obj", scene, function (newMeshes) {
        var mesh = scene.meshes[0];
        var ANote0VideoMat = new BABYLON.StandardMaterial("m", scene);
        var ANote0VideoVidTex = new BABYLON.VideoTexture("vidtex",wallPath, scene);
        ANote0VideoVidTex.video.muted = true;
        ANote0VideoMat.diffuseTexture = ANote0VideoVidTex;
        
        // ANote0VideoMat.roughness = 1;
        mesh.material = ANote0VideoMat;
        var floor = scene.meshes[1]

        var VideoFloorMat = new BABYLON.StandardMaterial("m", scene);
        var VideoFloorVidTex = new BABYLON.VideoTexture("vidtex",groundPath, scene);
        VideoFloorVidTex.video.muted = true;
        VideoFloorMat.diffuseTexture = VideoFloorVidTex;
        floor.material = VideoFloorMat;
        // scene.activeCamera = null;
        // scene.createDefaultCameraOrLight(true);
    });
  };

  render() {
    return (
      <canvas
        style={{ width: window.innerWidth, height: window.innerHeight }}
        ref={canvas => {
          this.canvas = canvas;
        }}
      />
    );
  }
}
export default BabylonScene;