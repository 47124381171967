import React, {useState,useEffect} from 'react'
import {apiUrl} from '../../hooks/Url' 
import { Link } from 'react-router-dom';
function Read() {
  const [data, setData] = useState([])
  let userId = localStorage.getItem('UserId');
  let token = localStorage.getItem('Authorization');
  //取得
  const fetchData = async () => {
    await fetch(apiUrl+'assets?author=' +userId)
    .then(response => response.json())
    .then(jsonResponse => {
      console.log(jsonResponse)
      setData(jsonResponse);
      // setLoading(false);

    });
  }
  //進入執行
  useEffect(()=>{
    fetchData();
  },[])

  const onDelete = id => {
    var yes = window.confirm('你確定嗎？');

    if (yes) {
      fetch(apiUrl+'assets/'+id ,{
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type':'application/json',
          "Authorization": token
        },
        body:JSON.stringify(data)
      }).then((res)=>{
        console.log('success')
        fetchData();
      })
      .catch((err)=>{console.log(err)})
    } else {
      return
    }
    
  }
  return (
    <div>
      列表
      <table className="table text-light">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">專案名稱</th>
            <th scope="col">日期</th>
            <th scope="col">管理</th>
          </tr>
        </thead>
        <tbody>
          { data.length > 0 ?
            data.map((item) =>{
              const { id, chinese_title, english_title,created_at} = item
              return(
                <tr key={id}>
                  <td>{id}</td>
                  <td className="w-25">{chinese_title}</td>
                  <td >{created_at} </td>
                  <td>
                     <Link className="btn btn-primary" to={`/dashboard/single/${id}`}>觀看</Link>
                    <button 
                      type="button" 
                      className="btn btn-light" 
                      onClick={()=>{onDelete(id)}}
                      >Delete</button>
                  </td>
              </tr>
              )
            }) :  <tr><td>no data</td></tr>

          }
        </tbody>
      </table>
      
    </div>
  )
}

export default Read
