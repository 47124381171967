import React from 'react'
import { Link } from 'react-router-dom';
import pic01 from '../../img/1403.jpg'
function home() {

  return (
    <section className="container">
      <div className="row text-center">
        <div className="col-lg-6 col-md-8 mx-auto mt-5 mb-5">
          <h1 className="fw-light text-light">Expo Moondream Reality</h1>
          <p className="lead title-light01">Hello，歡迎來到夢境現實這裡可以看到所有人的作品.</p>

        </div>
        <ul className="cards">
          <li>
            <div className="cover"><img src={pic01} alt=""/> <div className="goExplore">GO EXPLORE</div></div>
            <div className="title ">MR投影作品01</div>
          </li>
          <li>
          <div className="cover"><img src={pic01} alt=""/> <div className="goExplore">GO EXPLORE</div></div>
            <div className="title">MR投影作品01</div>
          </li>
          <li>
          <div className="cover"><img src={pic01} alt=""/> <div className="goExplore">GO EXPLORE</div></div>
            <div className="title">MR投影作品01</div>
          </li>
          <li>
          <div className="cover"><img src={pic01} alt=""/> <div className="goExplore">GO EXPLORE</div></div>
            <div className="title">MR投影作品01</div>
          </li>
        </ul>
      </div>
      
    </section>
  )
}

export default home
