import React ,{useEffect , useState}from 'react'
import SceneComponent from './SceneComponent'
import SceneObj from './SceneObj'
import {apiUrl} from '../../hooks/Url' 
function Single(props) {
  const [wallVideoPath , setWallVideoPath] = useState('')
  const [groundVideoPath , setGroundVideoPath] = useState('')
  const [isFetchFinished, setIsFetchFinished] = useState(false);
  useEffect(async () => {
    let token = localStorage.getItem('Authorization');
    const data = await fetch(apiUrl+'assets/'+ props.match.params.id,{
      headers: {
        'Content-Type':'application/json',
        "Authorization": token
      },
    })
    .then(response => {
      return response.json()
    }).then(data => {
      if(data.wall_video.length > 0){
        setWallVideoPath(data.wall_video[0].file)
      }
      if(data.ground_video.length>0){
        setGroundVideoPath(data.ground_video[0].file)
      }
      setIsFetchFinished(true);
      console.log(wallVideoPath)
      
      
    },[])

  })
  if(!isFetchFinished) return <div>Loading</div>;
  return (
    <div >

      {/* <SceneComponent/> */}
      <SceneObj assetsid={props.match.params.id} wallPath={wallVideoPath} groundPath={groundVideoPath} />
    </div>
  )
}

export default Single
