import React ,{useState} from 'react'
import { useForm } from "react-hook-form";
import {apiUrl} from '../../hooks/Url' 
function Register(props) {
  const { register, handleSubmit} = useForm();
  const [isShowing, setIsShowing] = useState(false);
  const [regsiterMessage , setRegsiterMessage]= useState("註冊")
  const [value, setValue] = useState("")
  //0 註冊 1 處理中 2註冊成功
  //檢查 發送
  const onSubmit = data => {
    console.log(data)
    // props.PostData(data)
    if(data.password !== data.confirmPassword){
      console.log('nonono')
      setValue('設定的密碼不一致')
      return
    }
    setValue('')
    PostRegisterUser(data)
    console.log('GO')
  }

  //註冊POST
  const PostRegisterUser = data =>{
    fetch(apiUrl+'register',{
      method: 'POST',
      headers: {
        'Content-Type':'application/json'
      },
      body:JSON.stringify(data)
    }).then((res)=>{
      if(res.status === 201){
        console.log('success')
        setRegsiterMessage('帳號申請成功!')
      }else if (res.status ===400){
        setValue('申請錯誤，可能已存在相同的Email，請重新檢查')
      }
    })
    .catch((err)=>{console.log(err)})
  }

  return (
    <div className="container text-light">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          
          <label htmlFor="InputName" className="form-label">使用者名稱</label>
          <input type="text" className="form-control" id="InputName"  ref={register} name="name"/>
        </div>
        <div className="mb-3">
          <label htmlFor="InputEmail" className="form-label">Email(登入帳號))</label>
          <input type="email" className="form-control" id="InputEmail" aria-describedby="emailHelp" ref={register} name="email"/>
        </div>
        <div className="mb-3">
          <label htmlFor="InputPassword" className="form-label">設定登入密碼</label>
          <input type="password" className="form-control" id="InputPassword" ref={register} name="password"/>
          
        </div>
        <div className="mb-3">
          <label htmlFor="InputConfirmPassword" className="form-label">再次輸入密碼</label>
          <input type="password" className="form-control" id="InputConfirmPassword" ref={register} name="confirmPassword"/>
          {value.length > 0 && <div className="text-danger">{value}</div>}
        </div>
        <button type="submit" className="btn btn-primary">{regsiterMessage}</button>
        

      </form>
    </div>
  )
}

export default Register
