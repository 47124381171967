import React from 'react'
import { Link } from 'react-router-dom';
function App() {
  return (
    <div className="text-light">
      hello Dashboard
      <Link className="p-2 text-light" to="/dashboard/create">新增上傳</Link>
      <Link className="p-2 text-light" to="/dashboard/read">你上傳的內容</Link>
    </div>
  )
}

export default App