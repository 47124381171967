import React,{useState} from 'react';
import { useForm } from "react-hook-form";
import {apiUrl} from '../../hooks/Url' 
import { Link } from 'react-router-dom';
const Login = props => {
  const { register, handleSubmit} = useForm();
  const [userToken , setUserToken] = useState("")
  const [userData , setUserData] = useState()
  const onSubmit = data => {
    console.log(data)
    // props.handleLogin(data)
    LoginUser(data)
  }

  //登入
  const LoginUser =data =>{
    setUserToken(btoa(`${data.email}:${data.password}`))
    let authString = btoa(`${data.email}:${data.password}`)
    localStorage.setItem('Authorization', 'Basic ' + authString) 
    //TODO
    // fetch(apiUrl+'login', {
    //   headers: new Headers({
    //     "Authorization": `Basic ${data.email}:${data.password}`
    //   }),
    // }).then(response => {
    //   if (!response.ok) throw new Error(response.status);
    //   return response.json();
    // })
    fetch(apiUrl+'user_itself', {
      headers: new Headers({
        "Authorization": 'Basic ' + authString
      }),
    })
    .then(response => response.json())
    .then(jsonResponse => {
      // console.log(jsonResponse)
      setUserData(jsonResponse)
      localStorage.setItem('UserId', jsonResponse.id) 
      localStorage.setItem('UserAccount', jsonResponse.email) 
      console.log(jsonResponse)
      props.handleLogin(true)

    });
  }
  return(
  <div className="container">
    <div className="mt-md-5">
      <div className="mx-auto">
        <div className="row">
          <div className="col align-self-center text-light">
            <h3 >登入</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label htmlFor="InputEmail" className="form-label">帳號</label>
                <input type="email" className="form-control" id="InputEmail" aria-describedby="emailHelp" ref={register} name="email"/>
              </div>
              <div className="mb-3">
                <label htmlFor="InputPassword" className="form-label">密碼</label>
                <input type="password" className="form-control" id="InputPassword" ref={register} name="password"/>
                
              </div>

              <input type="submit" className="btn btn-primary" value="送出"/> or  <Link to="/register" className="text-light">註冊</Link>
            </form>
            {/* <button onClick={props.handleLogin}>Log In</button>
            <p>Logged in status: {props.user}</p> */}
            
          </div>

        </div> 
      </div>
    </div>
  </div>
  )

}
export default Login;