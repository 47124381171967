import React from 'react';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className='container'>
      <div className="row mt-3">
        <div className="col align-self-center mx-auto">

        <div class="card mt-3 mb-3">
          <div class="card-header">
            檔案上傳規範
          </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <p>下列規範說明在 Expo Moondream Reality 上傳專案與影片時需要遵守的格式規格。</p>
              <ul className="terms-list fs-6">
                <li><div className="fw-bolder">檔案格式 </div>
                    <div>preview影片：mp4、mov、avi 不侷，檔案大小500MB以下</div> 
                    <div>專案：exe檔，檔案大小500MB以下</div>
                </li>
                <li><div className="fw-bolder">畫面播放速率</div>
                    <div>30fps</div>
                </li>
                <li><div className="fw-bolder">影片解析度</div>
                    <div>牆面影片  1920*935</div>
                    <div>地面影片   1920*1920</div>

                </li>
              </ul>
            </blockquote>
          </div>
        </div>  

        <div class="card mt-3 mb-3">
          <div class="card-header">
            常見問題
          </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <ul className="terms-list fs-6">
                <li> <div className="fw-bolder">如何成為Expo Moondream Reality線上策展人? </div> 
                    <div>Expo Moondream Reality是公開的線上策展平台。只需註冊並且上傳作品，你就是我們的線上策展人。</div> 
                </li>
                <li><div className="fw-bolder">誰能夠看到我的作品?</div>
                    <div>所有進到Expo Moondream Reality的使用者、旅人都能看到你上傳的作品！</div>
                </li>
                <li><div className="fw-bolder">我的作品會在Moondream Reality播放嗎?</div>
                    <div>夢境現實團隊會仔細的看過每一位策展人的作品！團隊會挑選優秀或是有趣的作品，再進一步與策展人聯繫在Moondream Reality播放。</div>

                </li>
              </ul>
            </blockquote>
          </div>
        </div> 
        <div class="card mb-3">
          <div class="card-header">
            注意事項
          </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <ul className="fs-6">
                <li>凡註冊會員與上傳個人作品至Expo Moondream Reality，即代表您同意將作品公開展示於Expo Moondream Reality。</li>
                <li>作品所有權屬於作者(或上傳單位)，Moondream Reality 不負擔與作品相關之法律責任。</li>
                <li>上傳的作品與其內容元件不得違反智慧財產。</li>
                <li>本網站保留最終解釋權。保留最終解釋權。</li>
              </ul>
            </blockquote>
          </div>
        </div>


        </div>
      </div>
    </div>
  )
}

export default Terms;